import * as React from "react";
import { useSession } from "@tbml/hooks/useSession";
import { useRouter } from "@tbml/shared-dependencies/router";
import { Text } from "@tbml/components/Typography";
import { Customer } from "../../components/Customer";

function Home(): JSX.Element {
  const [session, sessionLoading] = useSession();
  const router = useRouter();
  const { issueId } = router.query;

  if (issueId === undefined || issueId instanceof Array) {
    return <Text>Could not retrieve issue id from url</Text>;
  }

  return <Customer loading={!session || sessionLoading} issueId={issueId} />;
}

export default Home;
